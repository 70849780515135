import React, { Component } from "react";
import "./style/Come.scss";

class Come extends Component {
  render() {
    return (
      <div className="ComeContainer">
        <div className="Come_TextBox">
          <div className="Come_Title">오시는 길</div>
          <div className="Come_Box">
            <div className="Come_One">주소</div>
            <div className="Come_Two">
              서울특별시 중구 다산로32, 428,429호(신당동, 남산타운 상가2동)
            </div>
          </div>
          <div className="Come_Box">
            <div className="Come_One">전화</div>
            <div className="Come_Two">1599-9964</div>
          </div>
          <div className="Come_Box">
            <div className="Come_One">팩스</div>
            <div className="Come_Two">02-517-1493</div>
          </div>
          <div className="Come_Box">
            <div className="Come_One">이메일</div>
            <div className="Come_Two">ejh0329@naver.com</div>
          </div>
        </div>
        <div className="Hanbit_Map" style={{ paddingTop: "30px" }}>
          <iframe
            title="HanbitLocation"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.25679620992!2d127.0092806!3d37.5490132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca308ea456451%3A0xbcb27814dc11ff2b!2s32%20Dasan-ro%2C%20Jung%20District%2C%20Seoul%2C%20H%C3%A0n%20Qu%E1%BB%91c!5e0!3m2!1svi!2s!4v1738833282158!5m2!1svi!2s"
            width="100%"
            height="100%"
            frameborder="0"
            allowfullscreen=""
          ></iframe>
        </div>
      </div>
    );
  }
}

export default Come;
